import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ButtonLink from "../components/button-link"
import { FiArchive, FiCreditCard, FiDollarSign, FiInstagram, FiLinkedin, FiMail, FiMessageCircle, FiPhone, FiTwitter } from "react-icons/fi"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import axios from "axios"
import { useState } from "react"
import ContactLinks from "../components/contact-links"

const ContactPage = () => {

  const [sendSucces, setSendSucces] = useState(false)

  function handleSubmit(e: any) {
    e.preventDefault()    
    axios({
      method: 'post',
      url: process.env.STRAPI_API_URL + '/api/messages',
      data: {
        data: {
          name: e.target.elements.name.value,
          email: e.target.elements.email.value,
          message: e.target.elements.message.value
        }
      }
    }).then(res => {
      setSendSucces(true)
    })
    e.target.reset()
  }

  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: 'Contact' }}/>
      
      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <PageTitle data={{
        title: 'Contact',
        description: 'Benieuwd wat we voor u kunnen betekenen?',
        image: 'https://cms.deltafuturum.nl/uploads/medium_contact_87926678cb.jpg'
      }}>
        <ContactLinks />
      </PageTitle>

      {/* Section */}
      <section className="container mx-auto grid grid-cols-12 gap-6 mb-16 items-start px-6 md:px-0">

        <div className="col-span-12 sm:col-span-5 md:col-span-6 bg-brand-blue-light py-16 bg-fill-left before:bg-brand-blue-light">
          <h3 className="text-2xl font-bold text-brand-blue mb-3">Bedrijfsinformatie</h3>
          <p className="flex gap-2 items-center mb-1">
            <FiArchive/> KVK 85639699
          </p>
          <p className="flex gap-2 items-center mb-1">
            <FiDollarSign/> BTW-ID 
          </p>
          <p className="flex gap-2 items-center mb-1">
            <FiCreditCard/> IBAN 
          </p>
          <h3 className="text-2xl font-bold text-brand-blue mt-6 mb-3">Onze socials</h3>
          <div className="flex gap-2">
            <a href="https://www.linkedin.com/company/delta-futurum" target="_blank" className="button">
              <FiLinkedin size="24" />
            </a>
            <a href="https://www.instagram.com/deltafuturum/" target="_blank" className="button">
              <FiInstagram size="24" />
            </a>
          </div>
        </div>

        <form className="col-span-12 sm:col-span-7 md:col-span-5 md:col-start-8 flex flex-col gap-6"
              onSubmit={handleSubmit}>
          {sendSucces && <span className="text-brand-green">Uw bericht is ontvangen, we nemen zo snel mogelijk contact met u op.</span>}
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="font-bold text-slate-500">Naam</label>
            <input required className="bg-brand-blue-light py-3 px-6 text-slate-500 transition-colors border-b-2 border-brand-blue-light hover:border-brand-blue" type="text" name="name" id="name" autoComplete="name" />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="email" className="font-bold text-slate-500">Email</label>
            <input required className="bg-brand-blue-light py-3 px-6 text-slate-500 transition-colors border-b-2 border-brand-blue-light hover:border-brand-blue" type="email" name="email" id="email" autoComplete="email" />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="message" className="font-bold text-slate-500">Bericht</label>
            <textarea required className="bg-brand-blue-light py-3 px-6 text-slate-500 transition-colors border-b-2 border-brand-blue-light hover:border-brand-blue" name="message" id="message"></textarea>
          </div>
          <button type="submit" className="button">Verzenden</button>
        </form>

      </section>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export default ContactPage